<!--重置交易密码3-->
<template>
  <div class="AlterBindPhone1">
    <br>
    <div class="safe" @click.prevent="returnSafe">&lt; 安全中心</div>
    <div class="AlterBindPhone2">
      <div class="AlterBindPhone3">
        <br><br><br><br>
        <div class="AlterBindPhone4">
          <div id="order1">
            <div class="order1">√</div>
            <div class="orderText">验证证件</div>
          </div>
          <div>
            <hr class="hr1">
            <div class="hidden">占位</div>
          </div>
          <div id="order2">
            <div class="order2">√</div>
            <div class="orderText">验证手机号</div>
          </div>
          <div>
            <hr class="hr2">
            <div class="hidden">占位</div>
          </div>
          <div id="order3">
            <div class="order3">3</div>
            <div class="orderText">重置密码</div>
          </div>
          <div>
            <hr class="hr3">
            <div class="hidden">占位</div>
          </div>
          <div id="order4">
            <div class="order4">4</div>
            <div class="orderText">操作成功</div>
          </div>
        </div>
        <br><br><br><br>
        <div>
          <h2>重置交易密码</h2>
        </div>
        <br><br>
        <div  class="checkCode">
          <div class="operDesc">请输入新密码</div>
          <div>
            <section class="login_verification">
              <input id="inputPass1"
                     type="password"
                     maxlength="8"
                     placeholder="密码"
                     v-model="tradePassword"
              />
            </section>
            <br><br>
          </div>
          <div class="operDesc">请再次输入新密码</div>
          <div>
            <section class="login_verification">
              <input id="inputPass2"
                     type="password"
                     maxlength="8"
                     placeholder="密码"
                     v-model="tradePasswordCopy"
              />
            </section>
          </div>
          <div class="hidden1">2次密码不相符</div>
        </div>
        <br><br>
        <div>
          <button class="next" @click.prevent="alterBindPhoNumNext">下一步</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {resetTradePass} from "../../api/resetTradePass";
export default {
  name: "ResetTradePass1",
  components: {},
  mounted() {

  },

  methods: {
    //重置交易密码
    alterBindPhoNumNext(){
      resetTradePass({tradePassword:this.tradePassword,tradePasswordCopy:this.tradePasswordCopy,mobileTel:this.phone,uuid:this.guid}).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          //跳转页面第四步
          this.$router.push({name:'ResetTradePass4'});
        }
      }).catch(err => {
        alert(err)
      })
    },
    //返回安全中心
    returnSafe(){
      //跳转页面返回安全中心
      this.$router.push({name:'安全中心'});
    },
  },

  data() {
    return {
      idNo: "",
      name: "",
      tradePassword: "",
      tradePasswordCopy: "",
      phone:"",
    }
  },

};
</script>


<style scoped>
div{
  text-align: center;
}
.AlterBindPhone1{
  width: 1000px;
  margin:0px auto;
}
.safe{
  text-align: left;
}
.order1,.order2,.order3,.order4{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #000000;
  line-height:60px;
  font-size: x-large;
}
.order1,.order2,.order3{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #C19B71;
}
#order1,#order2,#order3{
  color: #C19B71;
}
.order1,.order2{
  background-color:#C19B71;
  color: white;
}
.AlterBindPhone4{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.orderText{
  font-size: smaller;
}
hr{
  width: 130px;
  background-color: #000000;
  height: 1px;/*设置高度会添加新设置的颜色，但hr中本来的灰色还是存在的。 */
  border: none;/*去掉hr中本来的边框，即原本的灰色。 */
}
.hr1,.hr2{
  background-color: #C19B71;
}
.hidden{
  visibility: hidden;
}
span{
  color: #c5231c;
}
input{
  width: 470px;
  height: 50px;
  border:  1px solid #000000;
  border-radius: 5px;
  text-indent: 10px;
}
.hidden1{
  color: #c5231c;
  text-align: left;
}
.operDesc,.hidden1{
  text-align: left;
  width: 470px;
  margin:0px auto;
}
.next{
  outline: none;
  height: 50px;
  width: 470px;
  border:1px solid #C19B71;
  background-color: #C19B71;
  border-radius: 5px;
}
.checkCode{
  margin:0px auto;
}
.numInput{
  width: 470px;
  margin:0px auto;
  display: flex;
  flex-wrap: nowrap;
}
.phoneNum{
  float: left;
  border: 1px solid #000000;
  width: 166px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px 0 0 5px;
}
#inputPhone{
  border-radius: 0 5px 5px 0;
  text-indent: 10px;
}
#inputName{
  width: 470px;
  text-indent: 10px;
}
</style>
