import request from '@/utils/request'

// 重置交易密码
export function resetTradePass(data) {

  return request({
    url: '/api/sale/user/personal/resetTradePwd',
    method: 'post',
    data
  })
}




